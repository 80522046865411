.hp__block__quote{
    color: #000!important;
}

.hp__block__quote-border {
    border: 4px solid #ebebeb;
    padding: 10px;
}

.hp__block__quote-wrapper {
    padding: 20px 30px;
}

.hp__block__quote__picture-round {
    p {
        color: #666;
        font-size: 15px;
        margin-top: 5px;
        max-width: 140px;
        margin: auto;
    }
    img {
        border-radius: 50%;
        width: 100%;
        max-width: 110px;
    }
}

.hp__block__quote-paragraph {
    margin-top: 10px;
    letter-spacing: 0.2px;
    font-weight: lighter;
}

.hp__block__quote-opinion {
    border-top: 3px solid #ebebeb;
    max-width: 1000px;
    margin: auto;

   button {
       color: #fff;
       background: #3b17d5;
       text-transform: uppercase;
       font-weight: 700;
       text-align: center;
       padding: 10px 15px;
       margin: 0px 3px;
       width: 105px;
       display: inline-block;
       border: none;
       font-size: 13px;
    }
}

.hp__block__quote-opinion-last-link {
    a,
    a:link,
    a:visited {
        color: #3b17d5;
        font-weight: 700;
        margin-top: 20px;
        margin-bottom: 25px;
        display: inline-block;
        cursor: pointer!important;
        pointer-events: all!important;
        text-decoration: none!important;
    }

    &-circle {
        height: 22px;
        width: 22px;
        margin-left: 5px;
    }

    &-arrow {
        height: 9px;
        position: relative;
        left: -15.5px;
        bottom: 0.5px;
    }
}



@media screen and (max-width: 480px ) {
    .hp__block__quote-wrapper {
        padding: 0px;
        text-align: center;
    }
    .hp__block__quote-opinion {
        & > a {
            margin: 5px auto;
            display: block;
        }
    }
}
