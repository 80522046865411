@media (max-width: $screen-xs-max){
  .hp__block--three {
    .col-xs-6:nth-child(1) {
      padding-right: 5px;
    }
    .col-xs-6:nth-child(2) {
      padding-left: 5px;
    }
      
      .hp__box--column--second {
          .hp__box__content--zwykle {
              margin-top: 0 !important;
          }
          .hp__box__image {
              margin-right: 10px !important;
              margin-left: -10px !important;
              background-repeat: no-repeat;
              background-position: 10px 0 !important;
          }
          .hp__box__content--zwykle {
              padding-left: 5px;
          }
      }
  }
}


.hp__block--three{

    .hp__box__social {
        margin-top: 12px !important;
    }

    .hp__block__row--first > a:nth-child(3) {
        padding-top: 10px;
        position: relative;
        display: flex;
        justify-content: center;
        .hp__box__image {align-self: flex-start; width: 50%;}
        .hp__box__content--zwykle { margin-left: 10px; width: 50%; }
        .hp__box__title { padding-top: 0;}
    }
}
