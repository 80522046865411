@media (max-width: $screen-xs-max){
  .hp__block--two {
      .col-xs-6:nth-child(1) {
        padding-right: 5px;
      }
      .col-xs-6:nth-child(2) {
        padding-left: 5px;
      }
      .hp__box--column--second {
          .hp__box__image {
              margin-right: 15px;
              margin-left: -15px;
          }
      }
  }
}


.hp__block--two {
    .hp__box__social {
        margin-top: 9px !important;
    }
}
