
.hp__box__tetragon-background {
    background-size: cover;
    background-attachment: scroll;
    background-position: center;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    cursor: pointer!important;
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    z-index: 1;
    color: #fff;

     &:hover, &:focus {
        color: #fff!important;
        text-decoration: none!important;
     }
}

.hp__box__background-tetragon-big {
    height: 400px;
}

.hp__box__background-tetragon-wide {
    height: 190px;
}


.hp__box__background-tetragon-wide-small {
    .hp__box__tetragon-title{
      font-size: 16px;
    }
    height: 170px;
}


.hp__box__tetragon-top {
    padding: 6px 9px;
    -ms-flex-item-align: start;
        align-self: flex-start;
    background: #a8bd15;
}


.hp__box__tetragon-title {
    -ms-flex-item-align: center;
        -ms-grid-row-align: center;
        align-self: center;
    width: 100%;
    font-size: 20px;
    font-weight: bold;
    text-align: center;
    padding: 0px 16px;
}

.hp__box__tetragon-gradient {
    background: -webkit-linear-gradient(rgba(22, 9, 78, 0.75) 1px, rgba(28, 16, 45, 0.35) 100%);
    background: linear-gradient(rgba(22, 9, 78, 0.75) 1px, rgba(28, 16, 45, 0.35) 100%);
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
}

.hp__box__trudat-social {
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    padding-top: 4px;
    -ms-flex-item-align: end;
        align-self: flex-end;
    border-top: 1px solid rgba(177, 177, 177, 0.52);
    margin-bottom: 1px;
    position: relative;
}


.hp__box__trudat-trending-icon {
    margin-right: 10px;
    height: 12px;
}



.hp__box__trudat-social-icon {
    height: 15px;
    margin-right: 3px;
}

.hp__box__trudat-social-group{
    margin-left: 10px;
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
}

.hp__box__social__trudat__triangle {
    display: inline-block;
    width: 0;
    height: 0;
    border-top: 8px solid transparent;
    border-right: 8px solid white;
    border-bottom: 8px solid transparent;
}

.hp__box__social__trudat__triangle-wrapper {
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
}

.hp__box__social__trudat__text {
    background: #fff;
    font-size: 11px;
    font-weight: bold;
    height: 16px;
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    color: #1b0b62;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    padding-right: 2px;
}



.hp__box__tetragon-third {
    margin-top: 20px!important;
}

@media screen and (max-width: 992px){
 .hp__box__tetragon-second{
    margin-top: 20px
 }
}

@media screen and (max-width: 480px){
.hp__box__tetragon-background, .hp__box__background-tetragon-wide {
    height: 280px;
 }
}
