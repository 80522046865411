.hp__block__light-tough-link {
    font-weight: bold;
    width: 120px;
    cursor: pointer;
    text-decoration: none!important;
    display: inline-block;
    margin: 0px 15px;
    letter-spacing: 1px;
    padding: 10px 20px;
    border: 2px solid #3b17d5;
    span{
        color: #333;
    }
}
