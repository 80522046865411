.hp__block.hp__block--title {
    .hp__box__title {
        color: $background-color;
        font-weight: 800;
        letter-spacing: 0;
        padding-left: 2px;
        position: relative;
        &.hp__box__title--background {
            color: $box-title-color;
            font-weight: 800;
            color: #FA3800;
            opacity: 0.1;
            font-size: 16px;
            letter-spacing: .75px;
            line-height: 1.3;
            padding-bottom: 5px;
        }
        &.hp__box__title--description {
            color: $lightbackground-darktext-color;
            font-family: 'atrament_stdregular';
        }
    }

    &.hp__block--title--large {
        padding-top: 148px;
    }

    h3 {
      font-size: 26px;
      position: relative;
      display: inline-block;
      width: auto;
      float: none;
      &::before {
          content: "";
          display: inline-block;
          position: absolute;
          width: 25px;
          background: #333;
          height: 4px;
          top: calc(50% - 2px);
          left: -40px;
        }
      &::after {
        content: "";
        display: inline-block;
        position: absolute;
        width: 25px;
        background: #333;
        height: 4px;
        top: calc(50% - 2px);
        right: -40px;
      }
    }

}


.hpe__block--with-background {
  .hp__block--title {
    .hp__box__title {
      color: #fff;
      &.hp__box__title--description {
        letter-spacing: 1px;
        margin-top: 1px;
        font-size: 16px;
        color: #fff;
      }
      .hp__box__title.hp__box__title--description {color: #fff;}
    }
    h3 {
      &::after {
        background: #fff;
      }
      &::before {
        background: #fff;
      }
    }
  }
}
