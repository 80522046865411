.hp__box--column {

  &.hp__box--column--alert,
  &.hp__box--column--alert-other,
  &.hp__box--column--alert-grey,
  &.hp__box--column--alert-orange {
    .hp__box__social {padding-bottom: 12px;}

    @media (max-width: $screen-xs-max){
      .hp__box__content--zwykle {
        padding-left: 2px;
        padding-right: 2px;

        .hp__box__title{
            padding: 15px;
        }
      }
    }
  }

  /* bez szarego*/
  &.hp__box--column--alert,
  &.hp__box--column--alert-other,
  &.hp__box--column--alert-orange {
    .hp__box__social {color: white !important;}
  }

  /* czerwony*/
  &.hp__box--column--alert {
    .hp__box__image,
    .hp__box__label,
    .hp__box__title,
    .hp__box__content--zwykle {
      background: $alert-color !important;
      color: $text-color !important;
    }

    &:hover {
      .hp__box__label,
      .hp__box__title {color: #660000 !important;}
    }
  }

  /* czarny*/
  &.hp__box--column--alert-other {
    .hp__box__image,
    .hp__box__label,
    .hp__box__title,
    .hp__box__content--zwykle {
      background: $background-color !important;
      color: $text-color !important;
    }

    &:hover {
      .hp__box__label,
      .hp__box__title {color: #999999 !important;}
    }
  }

  /* szary*/
  &.hp__box--column--alert-grey {
    .hp__box__image,
    .hp__box__label,
    .hp__box__title,
    .hp__box__content--zwykle {
      background: #f3f3f3 !important;
      /* color: $text-color !important;*/
    }
  }

  /* pomarańczowy*/
  // &.hp__box--column--alert-orange {
  //   .hp__box__image,
  //   .hp__box__label,
  //   .hp__box__title,
  //   .hp__box__content--zwykle {
  //     background: $orange !important;
  //     color: $text-color !important;
  //   }
  //
  //   &:hover {
  //     .hp__box__label,
  //     .hp__box__title {color: #990000 !important;}
  //   }
  // }
}

.hp__block--four,
.hp__block--three,
.hp__block--two,
.hp__block--one{
    .hp__box__title {
      padding-top: 12px;
      padding-bottom: 5px;
      font-weight: bold;
    }

    .hp__box__image,
    .hp__box__image--other {
        padding: 0;
        .hp__box__image--inner {
            /* margin: auto -15px;*/
            width:100%;
            float: left;
        }
    }
    .hp__box__blog__text{
      font-weight: 400;
    }
    .hp__block__row {
        .hp__box--column--blog {
            color: $background-color !important;
            .hp__box__blog {

                padding: 0;
                display: table;

                .hp__blog__photo {
                	display: table-cell;
                    height: 50px;
                    width: auto;
                    margin-right: 10px;
                }
                .hp__box__label {
                	display: table-cell;
                	position: static;
                	background: transparent;
                	color: $background-color !important;
                	text-align: left;
                	padding: 0;
                	width: 100%;
                	font-size: 15px;
                	font-weight: bold;
                	white-space: normal;
                }
            }
            .hp__box__title {
            	color: $background-color !important;
            	font-size: 18px;
            	padding-top: 0;
            }

            .hp__box__blog__text {
                padding:0;
                margin: 6px 0 0 0;
            }

            .hp__box__social {
                color: $social-color;
                margin-top: 1px;
            }
        }
    }


    .hp__box__label {
      position: absolute;
      bottom: 0;
      left: 0;
      margin-bottom: 0;
    }

    .hp__box__image {
        background-repeat: no-repeat;
        background-position: center top;
        top: 0;
        bottom: auto;

    }
    .hp__box__content--zwykle {
        padding-left: 0;
        padding-right: 0;
        color: $background-color !important;
        .hp__box__list {
            color: $news-list-background;
            font-size: 14px;
            letter-spacing: -.5px;
            list-style-position: inside;
            list-style-type: square;
            margin-bottom: 0;
            margin-top: 8px;
            padding: 0;
            .hp__box__list__element {
                padding-left: 2px;
                &:before {
                    content: "";
                    display: inline-block;
                    height: 1px;
                    margin-left: -22px;
                    margin-top: -1px;
                    width: 1px;
                }
            }
        }
    }

    .hp__box__social {
        margin-top: 10px;
        color: $social-color !important;
    }
}

.hp__block--four,
.hp__block--two,
.hp__block--three {
    .hp__box__title {
        font-size: 14px;
        line-height: 1.3;
        padding-top: 5px;
    }

    .hp__box__title--strong {
        font-weight: 700;
    }
}
.hp__block__row--first {
    .hp__box__title {
        margin-top: 0;
    }
}




.hpe__block.hpe__block--with-background {
  .hp__block--four,
  .hp__block--three,
  .hp__block--two,
  .hp__block--one {
    .hp__box__content--zwykle {
      color: white !important;
      .hp__box__label,
      .hp__box__content--zwykle, {
        color: white !important;
      }
    }
    .hp__box__social,
    .hp__box--column:hover .hp__box__title {
      color: white !important;
    }

    .hp__block__row {
      .hp__box--column--blog {
        .hp__box__title,
        .hp__box__blog .hp__box__label {color: white !important;}

        .hp__box__blog__content .hp__box__blog__text {color: white !important; opacity: 0.8;}
      }
    }
  }
}


@media (max-width: $screen-xs-max){
    .hp__block--four,
    .hp__block--three,
    .hp__block--two,
    .hp__block--one {
        .hp__box--column {
            padding-left: 15px;
            padding-right: 15px;
        }
    }
}



.hp__box__content--zwykle.caption.on-picture {
    background: -webkit-linear-gradient(rgba(0, 0, 0, 0), rgba(3, 3, 32, 0.9));
    background: linear-gradient(rgba(0, 0, 0, 0), rgba(3, 3, 32, 0.9));
    position: absolute;
    padding: 5px;
    bottom: 0;
    left: 15px;
    right: 15px;
    width: auto;
    color: #FFF!important;

    .hp__box__label {
        position: relative;
        top: 0;
    }

    .hp__box__title {
		font-size: 18px;
		padding-top: 5px;
	}
}



/* ads - tapeta na całą stronę*/
.container {
  &--fixed-bg {
    background: white;
  }
}
