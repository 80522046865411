.hp__block__month {
    color: #000!important;
    h3 {
       text-transform: uppercase;
       margin: 15px 0;
    }
    h4 {
        margin-bottom: 2px;
        font-weight: 400;
    }
}

.hp__block__month-wrapper {
    border: 4px solid #ebebeb;
    max-width: 450px;
    margin: auto;
}

.hp__block__month-truth-negative-margin {
    margin: 0px -4px; // this goes on the <Box /> element
}

.hp__block__month-truth {
    a {
    color: #fff;
    background: #3b17d5;
    padding: 8px 15px;
    display: inline-block;
    text-transform: uppercase;
    font-weight: 700;
    margin-bottom: 15px;
    }
}
