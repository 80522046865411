.trudat__result {
	font-size: 17.5px;
	
    &--true,
    &--false {
        display: inline-block;
        background-size: contain;
        background-repeat: no-repeat;
        line-height: 27px;
    }
    
    &--true {
        padding-left: 33px;
        background-image: url("../../../../assets/img/trudat-true.png");
        background-position: left center;
        color: #009007;
    }
    
    &--false {
        padding-right: 33px;
        background-image: url("../../../../assets/img/trudat-false.png");
        background-position: right center;
        color: #bf3d27;
    }
}