.hp__box__video {
    position: absolute;
    top: 0;
    left: 0;
    height:100%;
        background-size: 16% 20% !important;
    background-position: center 40% !important;
}

.hp__video-icon {
  circle {stroke: white;}
  path {fill: white;}
}

.hp__box--main {
  .hp__video-icon {
    height: 160px;
    width: 160px;
    margin: 245px auto 0 auto;
    display: block;
    margin: auto;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;

    @media (max-width: $screen-xs-max){
      margin-top: 59px;
      height: 50px;
      width: 52px;
    }
  }
}
.hp__box--small {
  .hp__video-icon {
    height: 60px;
    width: 60px;
    margin: 68px auto 150px auto;
    display: block;

    @media (max-width: $screen-xs-max){
      margin-top: 36px;
      height: 40px;
      width: 40px;
    }

  }
}

@media (max-width: $screen-xs-max) {
    .hp__video-icon {
        height: 50px;
        width: 50px;
        margin: auto auto 112px 135px;
    }
}

.hp__block--four,
.hp__block--three,
.hp__block--two,
.hp__block--one {
    .hp__box__video {
        display: block;
        position: relative;
        width:100%;
        @media (max-width: $screen-xs-max){
            height: 100px;
        }
    }
}
.hp__block--four,
.hp__block--three,
.hp__block--two,
.hp__block--one {
    .hp__video-icon {
        position: absolute;
        max-width: 100%;
        margin: auto;
        left: 0;
        right: 0;
        bottom: 0;
        top: 0;
    }
}
.hp__block--four {
    .hp__video-icon {
        @media (max-width: $screen-xs-max){
            height: 30px;
            width: 32px;
            //margin-left: 55px; --- wyrównanie do psdka
            //margin-top: 25px; --- wyrównanie do psdka
        }
    }
}
.hp__block--three {
    .hp__box__video {
        @media (max-width: $screen-xs-max){
            height: 98px;
        }
    }
    .hp__video-icon {
        @media (max-width: $screen-xs-max){
            height: 30px;
            width: 32px;
            //margin-left: 134px; --- wyrównanie do psdka
            //margin-top: 85px; --- wyrównanie do psdka
        }
    }
}
.hp__block--two {
    .hp__box__video {
        @media (max-width: $screen-xs-max){
            height: 100px;
        }
    }
    .hp__video-icon {
        @media (max-width: $screen-xs-max){
            height: 30px;
            width: 32px;
        }
    }
}
.hp__block--one {
    .hp__box__video {
        @media (max-width: $screen-xs-max){
            height: 100px;
        }
    }
    .hp__video-icon {
        @media (max-width: $screen-xs-max){
            height: 60px;
            width: 64px;
        }
    }
}
