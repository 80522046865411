body {
  font-size: 16px;
  line-height: 1.4;
}

//@import url('https://fonts.googleapis.com/css2?family=Rubik:wght@300;400;500;700&display=swap&subset=latin-ext');
//@import url('https://fonts.googleapis.com/css?family=Playfair+Display&display=swap&subset=latin-ext');
//@import url('https://fonts.googleapis.com/css?family=Roboto:400,500&display=swap&subset=latin-ext');
//@import url('https://fonts.googleapis.com/css2?family=Source+Serif+Pro:wght@400;600;700&display=swap');
//@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,500;0,600;1,500&display=swap');

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6{
    line-height: 1.2;
}
@media (max-width: $screen-xs-max){
    h1, .h1 {
        font-size: 18px;
    }
    h2, h3, h4, h5,
    .h2, .h3, .h4, .h5 {
        font-size: 15px;
    }
    h6, .h6 {
        font-size: 14px;
    }

}
h1, .h1 {
    font-size: 30px;
}
h2, .h2 {
    font-size: 24px;
}
h3, .h3 {
    font-size: 21px;
}
h4, .h4 {
    font-size: 18px;
}
h5, .h5 {
    font-size: 16px;
}
h6, .h6 {
    font-size: 14px;
}

.ss-facebook {
    font-family: 'ss-social-regular';
    font-size: 11px;
    margin-left: 2px;
    margin-right: 3px;
}
.ss-chat {
    font-family: 'ss-standard';
    font-size: 12px;
    margin-left: 4px;
    margin-right: 3px;
}
.ss-search {
    font-family: 'ss-standard';
    font-size: 18px;
}

.ss-facebook-lg,
.ss-twitter,
.ss-youtube,
.ss-instagram {
    font-family: 'ss-social-regular';
    font-size: 30px;
}
