$bootstrap-sass-asset-helper: false !default;
//== Colors
//
//## Gray and brand colors for use across Bootstrap.

$white:                  #fff !default;
$black:                  #000 !default; // #222
$almostblack: #221f1f;
$lightalmostblack: #221f20;
$darkergray: #333;
$darkgray: #666;
$middarkgray: #999;
$normalgray: #acacac;
$midgray: #b9b9b9;
$lightgray: #ccc;
$midlightgray: #d4d4d4;
$midlightergray: #ebebeb;
$lightergray: #eee;
$red: #ed0202;
$orange: #ff6600;
$lighterorange: #ffc39e;
$lightorange: #fbe6e0;
$purple: #211165;
$violet: #2d1193;
$violet2: #330099;
$darkblue: #2a4893;
$blue: #2aadf4;
$green: #009900;
