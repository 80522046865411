.hp__box,
.hp__box--main {
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  .caption {
      padding: 22px 30px;
  }
  .hp__box__title {
      font-size: 36px;

      &.hp__box__title--mega {
        font-size: 90px;
        strong {font-weight: 800;}
      }
  }
  .hp__box__title--strong {
      display: block;
      font-weight: 800;
      font-size: 36px;
  }

}

.hp__box--main,
.hp__box--column,
.hp__box--small {
  margin: 0;
  padding: 0;
}

.hp__box__social {
    font-size: 12px;
    margin-top: 12px;
}

.hp__box--column {
    .news-content {
        font-weight: 400;
        font-size: 21px;
        padding-left: 13px;
        padding-right: 30px;
        .news-content-text {
            margin-bottom: 0;
        }
    }
}

.hp__box--small {
    .hp__box__social {
        margin-bottom: 2px;
        margin-top: 9px;
    }
    .hp__box__title {
        font-size: 21px;
        .hp__box__title--strong {
            font-weight: 700;
            font-size: 21px;
            margin-bottom: 0;
        }
    }
}


.hp__box--small--first,
.hp__box--small--third {
    .hp__box--small__content {
        display: block;
        min-height:100%;
        position:relative;
        width:100%;
    }
}

.hp__box--small--second {
    .hp__box__social {
        margin-top: 12px;
        margin-bottom: 1px;
    }
}

.hp__box--small--third {
    .hp__box__social {
        margin-top: 13px;
    }
}


.hp__box__label {
    background-color: $header-news-background;
    color: $text-color;
    display: inline-block;
    font-weight: normal;
    font-size: 11px;
    margin-bottom: 6px;
    padding: 2px 6px 1px 6px;
    text-align: center;
    width: auto;
    vertical-align: middle;
    white-space: nowrap;
}


.hp__box__content {
    display:block;
    padding-bottom: 20px;
    padding-left:0;
    padding-right:0;
    position: absolute;
    bottom:0;
    right:0;
}

.hp__box__gradient {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    /*height: 820px;*/
}

.hp__box__material-partnera {
    background: $social-color;
    color: $lightbackground-darktext-color;
    font-weight: 400;
    font-size: 10px;
    min-height: 16px;
    /* min-width: 55px;*/
    padding: 1px 5px;
    position: absolute;
    left: 0;
    top: 0;
}


.hp__box--main {
  .hp__box__content {
    background-image: linear-gradient(transparent, rgba(#030320, 0.9));
  }
}
