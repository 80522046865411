.hp__block--special {
    .hp__box--column {
        font-weight: 400;
        background-color: $header-news-background;
        .hp__box__title {
            letter-spacing: -1.5px;
        }
    }
    .hp__box--column--inverse {
        &.hp__box--column,
        .hp__box__content--special{
            color: $lightbackground-darktext-color !important;
        }
    }



    .hp__box__logo {
        // height: 40px;
        max-width: 90%;
        width: 150px;
    }
    .hp__box__logo__title {
        margin-bottom: 5px;
    }
    //-----------------------------------------------------view-xs
    @media (max-width: $screen-xs-max){
        .hp__box--main {
            max-height: 215px;
            .hp__box__content {
                padding-left: 0;
                padding-right: 10px;
                //padding-bottom: 0;
                padding-bottom: 11px;
                .hp__box__title {
                    text-align: center;
                    padding-right: 0;
                    padding-left: 0;
                    padding-bottom: 0;
                }
            }
        }
        .hp__box--column {
            clear: both;
            position: relative;
            float: left;
        }

        .hp__box--small__content {
            .hp__box__title {
                line-height: 1.3;
            }
        }

        &.hp__block--special--1{
            .hp__box--column {
                .hp__box__content--special {
                    text-align: center;
                    font-size: 16px;
                    line-height: 1.3;
                    margin: 0;
                    font-weight: bold;
                    color: #000;
                    padding-top: 30px;
                }
                .hp__box__footer {
                    position: relative;
                    .hp__box__logo {
                        // height: 40px;
                        max-width: 90%;
                        width: 150px;
                        left: -5px;
                    }
                    .hp__box__logo__title {
                        font-size: 15px;
                        color: #000;
                        padding-left: 5px;
                        padding-bottom: 1px;
                        margin-bottom: 0;
                        padding-top: 16px;
                        text-align: center;
                    }
                }
            }
        }
        &.hp__block--special--3,
        &.hp__block--special--4 {
            .hp__box--small {
                min-height: 215px;
                .hp__box--small__content{
                    min-height: 215px;
                    height: 215px;
                }
            }
        }
        &.hp__block--special--4 {
            .hp__box__logo__title {
                margin-bottom: 4px !important;
            }
        }
        &.hp__block--special--3,
        &.hp__block--special--4{
            .hp__box--column {
                &--special-one {
                    float: left;
                }
                &--special-two {
                    clear: right;
                    float: right;
                }
            }
            .hp__box--small {
                &.text-logo {
                    .hp__box__title {
                        font-size: 16px !important;
                        line-height: 1.2 !important;
                        padding-left:10px !important;
                        padding-right: 10px !important;
                        padding-top: 25px;
                    }
                    .hp__box__footer {
                        .hp__box__logo__title {
                            letter-spacing: 0;
                            // left: 5px;
                        }
                    }
                    .hp__box__content--special {
                        padding: 0 10px 0 10px;
                    }
                }
            }
            .hp__box--small--third {
                .hp__box__footer {
                    bottom: 0;
                }
            }
        }
        &.hp__block--special--3,
        &.hp__block--special--4{
            .text-logo {
                .hp__box--small__content{
                    position:absolute;
                    top: 0;
                }
                .hp__box__footer {
                    position: absolute;
                    left:0;
                    right:0;
                    bottom: 0;
                }
            }
        }
        &.hp__block--special--4 {
            .text-logo {
                margin-bottom: 45px;
                min-height: 170px;
                //height:170px;
                .hp__box--small__content {
                    min-height: 170px;
                    //height:170px;

                    .hp__box__logo {
                        // left: -1px !important;
                        // padding-right: 1px !important;
                    }
                }
            }
        }
    }
}
.hp__box__footer--with-padding {
    padding-bottom: 45px;
    .hp__box__logo__title {
        font-size: 12px;
        padding-left: 10px;
        padding-top: 10px;
        padding-bottom: 10px;
    }
}

.hp__block--special--3 {
    .text-logo {
    }
    .hp__box__logo__title {
        // padding-left: 4px !important;
    }
}
.hp__block--special--4 {
    .hp__box--small--third{
        .hp__box--small__content{
            background-color: $text-color;
            .hp__box__footer,
            .hp__box__title {
                color: $darkgray !important;
            }
        }
    }
}
.hp__block--special--3,
.hp__block--special--4
{
    .hp__box--small{
        .hp__box__title {
            margin-bottom: 0;
            padding-left: 13px;
            letter-spacing: 0;
            .hp__box__title--strong {
                font-size: 21px;
            }
        }
    }
    .hp__box--small--first {
        .hp__box__social {
            margin-top: 9px;
        }
    }
    .hp__box--small--second,
    .hp__box--small--third {
        &.second__image,
        &.third__image{
            .hp__box__social {
                margin-top: 8px;

            }
        }
        &.third__image--other{
            .hp__box__social {
                margin-top: 13px;
            }
        }
    }
}
.hp__block--special--3,
.hp__block--special--4{
    .hp__box--small--third {
        &.text-logo {
            .hp__box__title {
                margin-left: auto;
                margin-right: auto;
                margin-top: 0;
                padding: 65px 30px 3px;
                text-align:center;
                font-size: 18px;
                font-family: inherit;
                text-transform: initial;
            }
        }
        .hp__box__content--special {
            color: $text-color;
            padding: 0 34px 0 38px;
            text-align: center;
        }
        .hp__box__logo__title {
            text-align: center;
            // padding-left: 3px !important;

        }
        .hp__box__logo {
            // height: 55px;
            max-width: 90%;
            width: 205px;
            // left: 5px;
        }
    }
}
