.hp__box__bloger {
  padding: 0 15px;
  padding-bottom: 50px;
  &__link {
    display: flex;
    align-items: center;
    position: relative;
    overflow: hidden;
  }

  &__photo {
    width: 40px; height: auto;
    flex-shrink: 0;
  }

  &__author {
    display: block;
    font-size: 15px	;
    font-weight: 700;
    color: #040200;
    margin: 0 0 0 12px;
    padding: 0;
  }

  &__separator {
    display: block;
    background: transparent;
    border-top: 2px solid #f60	;
    width: 95%;
    margin: 0px;
  }

  &__content {
    padding-top: 9px;

    a:hover {text-decoration: none;}
    a,
    .hp__box__title {
      color: #040200;
      font-weight: 300;
      font-size: 18px;
      margin-bottom: 8px;
    }

    .hp__box__blog__text {
      font-size: 14px;
      color: #040200;
      padding-top: 0px;
      margin: 8px 0;
      margin-bottom: 0;
      font-weight: 500;
    }

    .hp__box__social {
      margin-top: 6px;
      color: #b5b5b5;
    }
  }

}
