@import "art/_body.scss";
@import "_trudat.scss";
@import "_contentStream.scss";
@import "_plista.scss";
@import "_onnetworkVideo";
@import "_liveitem";

.art {
  font-size: 18px;
  font-weight: lighter;
  line-height: 28px;
  color: #131212;
  margin: 0 0 20px 0;
  position: relative;

  .gallery {
    margin: 10px 0;
    img {
      width: 100%;
    }
  }
  
  .desc {
    font-size: 11px;
    line-height: 1;
    margin-top: 5px;
  }

  .block.pos_indent {
    margin: 25px 0;
    .block-inside {
      padding-left: 20px;
      padding-right: 20px;
    }
    .ls_trudat-blue {
      border-left: 4px solid #3b17d5;
      color: #666666;
      font-size: 18px;
      font-weight: bold;
    }
  }

  .title {
    line-height: 22px;
    color: #666666;
    font-size: 18px;
    font-weight: bold;
    text-transform: uppercase;
  }

  .art__vote {
    text-align: center;
    border: 4px solid #ebebeb;
    padding: 25px 0px;
    margin: 0px -20px;
  }

  .art__vote__title {
    font-size: 26px;
    font-weight: bold;
    color: #000;
    margin-bottom: 20px;
  }

  .art__vote__buttons button {
    color: #fff;
    background: #3b17d5;
    text-transform: uppercase;
    font-weight: 700;
    text-align: center;
    padding: 10px 15px;
    margin: 0px 8px;
    width: 100px;
    display: inline-block;
    font-size: 13px;
    border: none;
  }

  .adslot__ad-wrapper {
    // margin-top: 18px; // 
    // margin-bottom: 18px;
    &--fixed{
      margin-top: 0;
      margin-bottom: 0;
    }

    /*
     * potrzebne dla dziwnych formatów których jednak nie chcemy wyśrodkowywać i nie mają ustawionej stałej szerokości
     */
    &--block{
      display: block !important;
    }
  }

  .football_crest_wrapper{
	 padding: 5px 5px 5px 5px;
   & > div > div {
     margin: 5px 0 !important;
   }
 }


  .art__conclusion {
    margin-top: 15px;
    background: #deff00;
    padding: 10px 20px 25px 20px;
    margin-left: -20px;
    margin-right: -20px;
    font-weight: 400;
    &__header {
      background-repeat: no-repeat;
      background-position: left center;
      background-size: 72px auto;
      padding-left: 75px;
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      line-height: 1.3;
      margin-bottom: 5px;
      padding-top: 20px;
      padding-bottom: 10px;
      line-height: 36px;
      font-size: 25px;
      h3 {
        margin: 5px;
        padding-right: 10px;
      }
    }

    &--true {
      background: #deff00;
      .art__conclusion__header {
        background-image: url('../../../../assets/img/trudat_tarcza.png');
      }
    }
    &--false {
      background: #ff9999;
      .art__conclusion__header {
        padding-left: 100px;
        background-size: 88px auto;
        background-image: url('../../../../assets/img/trudat_tarcza-false.png');
      }
    }

    &__vote-description {
    	font-size: 17px;
    	line-height: 19px;
    }
  }

  .art__conclusion__share {
    margin-top: 20px;
    padding: 20px;
    border: 4px solid #ebebeb;
    text-align: center;
    margin: 20px -20px 0px -20px;
    .art__conclusion__share__title {
      font-weight: bold;
      color: #000;
      text-transform: uppercase;
      font-size: 28px;
      margin-bottom: 10px;
    }
    .art__conclusion__share__title-small {
      font-weight: bold;
      font-size: 17px;
      max-width: 300px;
      margin: 10px auto;
      color: #000;
    }
  }

  .art__conclusion__share__social {
    margin-top: 23px;
    font-size: 15px;
    font-weight: 400;
    a,
    a:visited {
      display: block;
      margin: 7px 0;
      color: #fff;
    }
    .art__conclusion__share__social__fb {
        background: #2a4893;
        background-repeat: no-repeat;
        background-size: 16px;
        padding: 12px 10px 12px 32px;
        background-position: 41px 11px;
        background-image: url('../../../../assets/img/facebook.png');
    }
    .art__conclusion__share__social__twitter {
      background: #2aadf4;
      background-position: 48px 50%;
      background-image: url('../../../../assets/img/twitter.png');
      background-size: 24px;
      background-repeat: no-repeat;
      padding: 12px 10px 12px 40px;
    }
  }
}
