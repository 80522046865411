.body-v2 {
    .hp__news-list {
        background-color: #1e1c1d;
        .hp__news-list__header {
            background-color:  #1e1c1d;
            text-align: center;
            padding-top: 10px;
        }
        .hp__news-list__header:after {
            border-top-color:  #1e1c1d;
        }
        .header-title {
            color: white;
            font-weight: bold;
            font-size: 28px;
            letter-spacing: 0.2px;
            display: inline-block;
            margin: 5px 10px 5px 0;
        }
        .header-date {
            color: #fff;
            font-size: 22px;
            font-weight: 500;
            display: inline-block;
            margin-right: 5px;
        }
        .more-info {
          background: #ff6600;
          font-size: 12px;
          padding: 4px 6px;
          letter-spacing: 0.3px;
        }
    }

    .hp__news-list__ad {
        background: white;
        left: 0; right: 0; bottom: 0;
        display: none; // what is this anyway?
        &:before {
        	background-image: -webkit-linear-gradient(transparent, white);
        	background-image: linear-gradient(transparent, white);
          display: none;
        }
        .hp__news-list__ad {
            clear: both;
            position: relative;
        }
    }

    .news-list-ordered {
        counter-reset:li;
        margin-left:0;
        padding-left:0;
        display: flex;
        overflow-x: auto;
        margin-left: 15px;
        margin-top: 5px;
        .hp__news-list__element {
            color: $text-color;
            font-weight: 500;
            position:relative; /* Create a positioning context */
            list-style:none; /* Disable the normal item numbering */
            left: 0;
            margin-left: 0;
            padding-right: 5px;
            a {
                color: #fff;
                display: block;
                line-height: 1.3;
                padding-left: 10px;
                text-decoration: none;
                font-size: 18px;
                font-weight: 600;
                width: 40vw;
                margin-top: 35px;
            }
        }
        .hp__news-list__element:before {
            content:counter(li); /* Use the counter as content */
            counter-increment:li; /* Increment the counter by 1 */
            /* Position and style the number */
            position:absolute;
            top: -25px;
            left:0px;
            box-sizing:border-box;
            width:35px;
            /* Some space between the number and the content in browsers that support
               generated content but not positioning it (Camino 2 is one example) */
            margin-right:8px;
            padding:3px;
            color: #fff;
            font-size: 45\  \   px;
            font-weight:normal;
            font-family:"atrament_stdregular", Arial, sans-serif;
            text-align:center;
        }
        .hp__news-list__element:last-child {
            margin-bottom:0;
        }
    }

}
