@media (max-width: $screen-xs-max){
    .hp__box--small__content {
        background-repeat: no-repeat;
        background-position: center top;
        background-size: 110% auto;
        top: 0;
        bottom: auto;
        .hp__box__title {
            padding-right: 10px;
        }
    }
    .hp__box__gradient {
        min-height: 215px;
    }
    .hp__block--mt-standard,
    .hp__block--video,
    .hp__block--special--4{
        .hp__box--small--third {
            .hp__box--small__content{
                /* background: $background-color url(../images/mt-standard/image-@2x_09.png) no-repeat center left/ 50% 100% !important;*/
                background-size: 55% auto;
                background-position: left center;
            }
        }
    }
    .hp__box--main {
        min-height: 215px;
        .hp__box__content {
            padding-bottom: 11px;
            padding-left: 20px;
        }
        .hp__box__title {
            clear: both;
            font-size: 17px;
            padding-right: 10px;
            &.hp__box__title--mega {font-size: 24px;}
        }
        .hp__box__title--strong {
            font-size:17px;
        }
        .hp__box__social {
            margin-top: 12px;
        }
    }
    .hp__box--main .hp__box__title--strong {
        display: inline;
    }

    .hp__block--mt-standard,
    .hp__block--video,
    .hp__block--special--3,
    .hp__block--special--4 {
      .hp__box--small {
        .hp__box__label {
        	display: none;
        	position: absolute;
        	top: -18px;
        }
      }
    }

    .hp__block--mt-standard,
    .hp__block--video,
    .hp__block--special--3,
    .hp__block--special--4 {
        .hp__box--small {
            /* background: $background-color url(assets/images/mt-standard/image-@2x_03.png) no-repeat center top/ 100% 107px;*/
            /* min-height: 322px !important;*/
            padding-bottom: 0 !important;
            overflow: hidden;

            &.hp__box--small--first,
            &.hp__box--small--second{
                height: auto;
                min-height: 230px;
                .hp__box--small__content {
                    height: auto;
                    min-height: 230px;
                }
            }

            .hp__box__content {
                position: absolute;
                top: 0;
                right: 0;
                margin-top: 119px;
                padding-top: 7px;
                background-color: #fff;
                .hp__box__title {
                    font-size: 14px;
                    padding-left: 9px;
                    .hp__box__title--strong {
                        font-size:16px;
                    }
                }
                &::after {
                  background-image: -webkit-linear-gradient(transparent, white);
                  background-image: linear-gradient(transparent, white);

                  content: '';
                  position: absolute;
                  left: 0; right: 0; bottom: 0;
                  height: 25px;
                }
            }
        }
    }

    .hp__block--mt-standard,
    .hp__block--video {
      .hp__box--small {
        &.hp__box--small--third{
            height: 110px;
            min-height: 110px;
            .hp__box--small__content {
                height: 110px;
                min-height: 110px;
            }
            .hp__box__content {
                width: 50%;
                margin-top: 0;
                padding-top: 10px;
            }
            .hp__video-icon {
              margin-right: 70%;
            }
        }
      }
    }

    .hp__news-list {
        .hp__news-list__header {
            margin-bottom: 8px;
            padding-right: 0;
            padding-left: 0;
        }
        .header-title {
            font-size: 16px;
            margin-top: 10px;
        }
        .header-date {
            font-size: 12px;
            margin-bottom: 8px;
            padding-left:3px;
        }
        .hp__news-list__content {
            padding-left: 0;
            padding-right: 0;
        }
        .hp__news-list__element {
            font-size: 14px;
            margin-top: 10px;
            margin-bottom: 0;
            padding-left: 2px;
            padding-right: 10px;
            margin-left: 15px;
            padding-bottom: 6px;
            &:before {
                font-size: 32px;
                left: -35px;
                top: -12px;
            }
        }
    }
    .hp__news-list__ad {
        /* height: 250px;*/
        position: relative;
    }

    .hp__block--four{
      .hp__box--column {
        height: 100%;
      }

      .hp__block__row--xs--first {
        .hp__box--column {
          padding-bottom: 13px !important;
        }
      }
      .hp__block__row--xs--first,
      .hp__block__row--xs--second {clear: both;}
    }
}
