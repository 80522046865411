.hp__block__announcement {
    h4 {
        color: #999999;
        text-transform: uppercase;
        font-size: 20px;
        margin: 18px 0;
    }
    h2 {
        color: #000000;
        font-size: 32px;
    }
    border: 4px solid #ebebeb;
    &-button {
        color: #fff;
        text-transform: uppercase;
        font-weight: bold;
        padding: 12px 20px;
        display: inline-block;
        background: #3b17d5;
        margin-bottom: 18px;
        cursor: pointer;
        outline: 0;
        border: 0;
        &:hover {
            color: #fff;
            text-decoration: none;
        }
    }
}

/*
.hp__block__announcement-button {
    color: #fff;
    text-transform: uppercase;
    font-weight: bold;
    padding: 12px 20px;
    display: inline-block;
    background: #3b17d5;
    margin-bottom: 18px;
}
*/
