.hp__block--one {
  .hp__box__content--zwykle {
    .hp__box__title {
      font-size: 23px;
    }
  }
  
  .hp__box__image {
  	> .lazy-image__container {
  		// margin-left: -20px; margin-right: -20px; width: auto;
  	}
  }
  
  .hp__box--column--alert-orange {
  	.hp__box__label {
  		margin-left: 12px;
  	}
  }
}
