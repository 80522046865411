/* ----------- used break-points -----------

$screen-xs-max:              767px;
$screen-sm-min:              768px;
$screen-md-max:              1199px;
$screen-lg:                  1200px;

@media (min-width: $screen-lg){
}
@media (min-width: $screen-sm-min) and (max-width: $screen-md-max){
}
@media (min-width: $screen-sm-min) {
}
@media (max-width: $screen-xs-max){
}

 ----------- used break-points ----------- */

section {
    display: block;
    position: relative;
}

.old-centered{
    float: none;
    margin-left:auto !important;
    margin-right: auto !important;
    text-align: center;
    display: block;
}

.no-margin-padding {
    margin: 0;
    padding: 0;
}

.no-padding {
    padding: 0;
}

.no-left-right-margin {
    margin: auto 0;
}

.list-unstyled {
  padding: 0;
  margin: 0;
  list-style: none;
}


.btn {
  border-radius: 0;

  &.btn-orange {
    color: white;
    background-color: $orange;
  }
}


.hp__block, .hp__box__pile {
    a {
        &:active,
            &:link,
            &:visited {
            color: $text-color;
        }
    }
}


.inline-text {display:inline;}


.gradient {
    background-image: -webkit-linear-gradient(315deg, rgba($orange,0.6) 0%, rgba(0,0,51,0.26) 42%);
    background-image: linear-gradient(135deg, rgba($orange,0.6) 0%, rgba(0,0,51,0.26) 42%);
}
