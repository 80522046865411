.hp__box__layers {
    color: $background-color;
    display: block;
  font-weight: 700;
    left:0;
    line-height: 1;
    padding-top: 0;
    position: absolute;
    text-align: center;
    top:50%;
    -webkit-transform: translate(0, -50%);
            transform: translate(0, -50%);
  margin-top: 0;
    width:100%;
    .hp__layers-text {
        position: absolute;
      bottom: 0;
        left: 0;
        right: 0;
        top:  0;
        margin-left: auto;
        margin-right: auto;
        margin-bottom: 0;
      padding-bottom: 0;
        text-align: center;
        float: none;
    }

    @media (max-width: $screen-xs-max) {
        font-size: 12px;
      /*margin-top: -30px;*/
      /*height: 60px;*/
        .hp__layers-text {
          line-height: 21px;
          margin-top: 9px;
          padding-left: 20px;
          padding-right: 12px;
        }

        .hp__layers-icon {
            height: 30px;
            width: 32px;

        }
    }
}


.hp__box--small {
  .hp__box__layers {
    top: 35%;
    @media (max-width: $screen-xs-max){ top: 54px; }
  }
}
