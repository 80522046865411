.article-body {
  blockquote.block {
	margin: 12px 0; padding: 0;
	> .block-inside {
		padding: 5px 20px;

		> p {margin: 0;}
	}

	> .style_gray {
		background: #E9E9E9;
	}

	> .ls_gray {
		border-left: 4px solid #DBDBDB;
	}

	h3 {
  	  margin: 5px 0;
  	  font-size: 30px;
  	}
	
  }

  .art__body__photo {
  	margin: 10px -5px;
  	.desc {
  	  padding: 1px 20px 7px 20px;
  	  font-size: 12px;
  	  color: #59595b;
  	  .src {color: #808183;}
  	  .bull {margin: 0 3px;}

  	}
  }

  &.article-body--trudat {
  	.header {
  	  text-align: center;

  	  .title {
  		border-top: 4px solid #ebebeb;
  		padding-top: 4px;
  		min-width: 65px;
  		display: inline-block;
  	  }
  	}
  }
}
