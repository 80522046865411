@media (max-width: $screen-xs-max) {
  .hp__block--four{
      .col-xs-6:nth-child(1) {
        padding-right: 5px;
      }
      .col-xs-6:nth-child(2) {
        padding-left: 5px;
      }
      .col-xs-6:nth-child(3) {
        padding-right: 5px;
      }
      .col-xs-6:nth-child(4) {
        padding-left: 5px;
      }
  }
}
