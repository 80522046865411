.hp__block {
  color: $text-color;
  padding: 0 0 20px 0;
  clear: both;

  .row {
    margin: 0;
  }

  .adslot {
    max-width: 1000px;
    margin: 0 auto;
  }

  &.hp__block--bloger {
    padding-left: 20px;
    padding-right: 20px;
  }
}


.hpe__block--with-background {
  padding-top: 24px;
  padding-bottom: 4px;
  margin-bottom: 20px;
}


// @media (max-width: $screen-xs-max){
//   padding-bottom: 30px;
//   &__row {
//     &--xs--second { clear: both; }
//   }
// }