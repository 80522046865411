
@media (max-width: $screen-xs-max){
    .hp__block--mt-standard {
      .hp__block--three {
        padding-top: 20px;
      }
        .hp__box--main {
            background-color: #dadada;
            max-height: 215px;
            .hp__box__content {
                padding-right: 30px;
                padding-bottom: 10px;
                text-align: center;

                .hp__box__title {
                    padding-right: 0;
                    padding-left: 10px;
                    padding-bottom: 0;
                }
            }
        }
        .hp__news-list__content {
            margin-bottom: 12px;
        }
        .hp__news-list__ad {
            bottom: 0;
            margin-bottom: 12px;
        }
    }
}


.hp__block--mt-standard{
  &.hp__block--mt-standard--breaking-news {
    $red: #fe0000;
    .hp__box__gradient {
      background-image: -webkit-linear-gradient(315deg, rgba($red,0.6) 0%, rgba(0,0,51,0.26) 42%);
      background-image: linear-gradient(135deg, rgba($red,0.6) 0%, rgba(0,0,51,0.26) 42%);
    }
    .hp__box__label,
    .hp__news-list .hp__news-list__header {
      background-color: $red;
    }
    .hp__news-list .hp__news-list__header:after {
      border-top-color: $red;
    }
    .news-list-ordered .hp__news-list__element:before {
      color: $red;
    }
    .hp__news-list .more-info {
      color: #999999;
    }
  }
}
