.hp {
  width: 100%;
  // overflow-x: hidden;
  // zmiana overflow dla scroll reklam w placeholderze #1965 
  overflow-x: clip !important;
  // zmiana z display: "block" ponieważ marginy children są ignorowane bez overflow-x: hidden przy screeningu
  display: inline-block
}

@import "hp/_variables.scss";
@import "hp/_bootstrap_variables.scss";
@import "hp/_typography.scss";
@import "hp/_global.scss";
@import "hp/block/_mt-standard.scss";
@import "hp/block/_one-image.scss";
@import "hp/block/_special1.scss";
@import "hp/block/_two.scss";
@import "hp/block/_three.scss";
@import "hp/block/_four.scss";
@import "hp/block/_one.scss";
@import "hp/_essentials.scss";
@import "hp/_box.scss";
@import "hp/block/_title.scss";
@import "hp/box/_blog.scss";
@import "hp/box/_bloger.scss";
@import "hp/box/_layers.scss";
@import "hp/_video-icon.scss";
// @import "hp/_hovery.scss";
@import "hp/box/_tetragon.scss";
@import "hp/block/_light-tough.scss";
@import "hp/block/_announcement.scss";
@import "hp/block/_quote.scss";
@import "hp/block/_month.scss";
@import "hp/block/_popular.scss";
@import "hp/_news-list.scss";
@import "hp/_xs.scss";
@import "hp/_block.scss";