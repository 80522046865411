* {
    box-sizing: border-box;
}

body {
    //$grotesk
    font-family: var(--fonts-grotesk);
    font-style: normal;
    -webkit-font-smoothing: antialiased;

    &.belka-top--active {
        padding-top: 60px;
        transition: padding-top 500ms ease-in-out;

        .nav-fixed {
            transition: top 500ms ease-in-out;
            top: 60px;

            //@bp4
            @media (min-width: 996px) {
                top: 150px;
            }
        }

        &.belka-top--full {
            padding-top: 265px;
            transition: padding-top 500ms ease-in-out;

            .nav-fixed {
                top: 265px;
            }
        }
    }

    .app-content {
        min-height: 90vh;
    }

    //@bp4
    @media (min-width: 996px) {
        background-position: center 118px !important;

        &.belka-top--active {
            padding-top: 150px;
            background-position: center 268px !important;
            transition: padding-top 500ms ease-in-out;

            &.belka-top--full {
                padding-top: 300px;
                transition: padding-top 500ms ease-in-out;
            }
        }
    }
}

a {
    color: #fff;
    text-decoration: none;
}

input,
button {
    &:focus {
        outline: 0px;
    }
}

.body-v2 {
    overflow-x: clip;
}

.text-center {
    text-align: center;
}

.overflow-hidden {
    overflow: hidden;
}

.brid-paragraph-wrapper {
    padding: 16px 0;
    //$grayscale100
    color: var(--colors-grayscale100);
    //$grayscale0
    background-color: var(--colors-grayscale0);

    //@bp4
    @media (min-width: 996px) {
        padding: 28px 0;
    }
}

body.adslot--tapeta,
body.screening_wallpaper {
    cursor: pointer;

    .gnt-screening {
        display: none;
        position: absolute;
        top: 118px;
        bottom: 0;
        left: 0;
        right: 0;
        z-index: -1;
        overflow: hidden;
        width: 100%;
        min-height: 1080px;
        will-change: top;

        //@bp4
        @media (min-width: 996px) {
            &.gnt-screening--clickable {
                display: block;
                cursor: pointer;
            }
        }
    }

    &.scrolling-down {
        .gnt-screening {
            position: fixed;
            top: 0;
        }
    }

    &.scrolling-up {
        .gnt-screening {
            transition: top 0.6s ease;
            position: fixed;
            top: 60px;
        }
    }

    #root {
        cursor: auto;
        isolation: isolate;
        margin: 0 auto;
        max-width: 100%;

        .hp {
            //$grayscale0
            background-color: var(--colors-grayscale0);
        }

        //Wyłączenie backgroundImage na mobile
        // //@bp2
        // @media (min-width: 375px) {
        //     max-width: 343px;
        // }

        // //@bp3
        // @media (min-width: 768px) {
        //     max-width: 774px;
        // }

        //@bp4
        @media (min-width: 996px) {
            max-width: 972px;
        }

        //@bp5 {
        @media (min-width: 1310px) {
            max-width: 1296px;
        }
    }

    .app-content {
        background-color: transparent;
    }
}

#pushAdUpBanner {
    z-index: 5000001 !important;
}

@media (max-width: 767px) {
    #qc-cmp2-ui {
        height: auto !important;
        max-height: 100% !important;

        .qc-cmp2-summary-info {
            max-height: 90px !important;
        }

        .qc-cmp2-footer {
            height: auto !important;
        }
    }
}

/*Support #6160 LCP pilne wzrost duży*/
@media (min-width: 768px) {
    #qc-cmp2-ui {
        .qc-cmp2-summary-info {
            max-height: 120px;
            padding: 0;
            margin-bottom: 1rem;
        }
    }
}

.bell-animation {
    @keyframes hang {
        0% {
            transform: rotate(-25deg);
        }

        5% {
            transform: rotate(50deg);
        }

        10% {
            transform: rotate(-25deg);
        }

        12% {
            transform: rotate(0);
        }
    }

    animation: hang 20s ease-out;
    animation-iteration-count: 3;
}

/*Musi być tu, a nie w komponencie - optymalizacja CLS #6059*/
.column-left,
.next-article-wrapper-box {
    min-height: 80vh;
}

.footer-placeholder {
    min-height: 940px;

    &>footer {
        height: 100%;
    }

    @media (min-width: 996px) {
        min-height: 344px;
    }
}

.author-date {
    min-height: 46px;
}

.article-swiper-arrow {
    position: fixed;
    display: none;
}