
.hp__block--one-image {
    .hp__box--main {
      background-color: #dadada;
      min-height: 182px;
    }
    .hp__box__title--strong {
        display: inline !important;
    }
    
    .hp__read-later {
    	top: 5px;
    	right: 20px;
    }
}
