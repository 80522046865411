@keyframes :global(podcastNextEpisode) {
    from {
        clip-path: inset(0px 100% 0px 0px);
        -webkit-clip-path: inset(0px 100% 0px 0px);
    }

    to {
        clip-path: inset(0px 0px 0px 0px);
        -webkit-clip-path: inset(0px 0px 0px 0px);
    }
}

:global {
    .podcastPlayerNav {
        $player: &;
        position: fixed;
        z-index: 100;
        bottom: 72px;
        left: 0;
        width: 100%;
        height: 72px;
        border-top: 1px solid;
        border-color: var(--colors-grayscale50);

        @media (min-width: 996px) {
            height: 96px;
            bottom: 0;
        }

        &--hide {
            display: none;
        }

        &__wrapper {
            display: flex;
            align-items: center;
            background: var(--colors-grayscale0);
            width: 100%;
            height: 100%;
            position: relative;
            padding: 8px;

            @media (min-width: 996px) {
                padding: 16px;
            }
        }

        &__poster {
            width: 40px;
            height: 40px;
            background: var(--colors-grayscale25);

            @media (min-width: 996px) {
                width: 64px;
                height: 64px;
            }

            img {
                width: 100%;
                height: 100%;
                object-position: center;
                object-fit: cover;
            }
        }

        &__titleWrapper {
            max-width: 35%;
            margin-left: 16px;
            margin-right: 1rem;

            @media (min-width: 996px) {
                max-width: 30%;
                margin-left: 12px;
                margin-right: 1rem;
            }

            p {
                margin: 0;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
            }
        }

        &__title {
            font-size: 12px;
            line-height: 16px;
            font-weight: 600;
            color: var(--colors-grayscale100);

            a {
                color: var(--colors-grayscale100) !important;
                text-decoration: none !important;
            }

            @media (min-width: 996px) {
                font-size: 16px;
                line-height: 18px;
            }
        }

        &__series {
            color: var(--colors-primary);
            font-weight: 700;
            font-size: 12px;
            line-height: 16px;

            a {
                color: var(--colors-primary) !important;
                text-decoration: none !important;
            }
        }

        &__controls {
            margin-left: 1.5rem;
            display: flex;
            justify-content: center;
            align-items: center;
            order: 1;

            @media (min-width: 996px) {
                order: unset;
                position: absolute;
                left: 50%;
                top: 50%;
                transform: translate(-50%, -50%);
                min-width: 128px;
            }

            svg {
                fill: var(--colors-grayscale100);
            }

            button {
                position: relative;
                background: none;
                align-items: center;
                justify-content: center;
                padding: 0;
                cursor: pointer;
            }

            &__play {
                display: flex;
                width: 36px;
                height: 36px;
                border-radius: 100%;
                border: 2px solid;
                border-color: var(--colors-grayscale25);

                svg {
                    width: 14px;
                }

                @media (min-width: 996px) {
                    width: 48px;
                    height: 48px;

                    svg {
                        width: auto;
                    }
                }

                #{$player}__progress {
                    display: none;
                    position: absolute;
                    transform: scale(0.25);
                    width: calc(400% + 10px);
                    //height: calc(100% + 4px);
                }

                &--played {
                    border: none;

                    svg {
                        fill: var(--colors-primary);
                    }

                    #{$player}__progress {
                        display: block;
                    }
                }
            }

            &__button {
                display: none;
                border: none;
                margin: 0 0 0 26px;
                width: 18px;
                height: 18px;

                &--prev {
                    margin: 0 26px 0 0;

                    svg {
                        transform: scaleX(-1);
                    }
                }

                @media (min-width: 996px) {
                    display: flex;
                }
            }

            :global(.adPlayButton) {
                width: 70%;
            }
        }

        &__progress {
            fill: none;
            stroke-width: 10px;
            filter: blur(2px);

            &__bg {
                fill: none;
                transform-origin: center center;
                stroke: var(--colors-grayscale100);
            }

            &__actual {
                fill: none;
                transform: rotate(270deg);
                transform-origin: center center;
                stroke: var(--colors-primary);
            }
        }

        &__volume {
            display: none;
            align-items: center;

            @media(min-width: 996px) {
                display: flex;
            }

            &__track {
                height: 4px;
                width: 200px;
                background: var(--colors-grayscale25);

                &__current {
                    position: absolute;
                    height: 100%;
                    background: var(--colors-primary);
                }
            }

            &__thumb {
                height: 12px;
                width: 12px;
                background: var(--colors-primary);
                border-radius: 100%;
            }

            &__button {
                display: flex;
                align-items: center;
                margin-right: 16px;
                background: none;
                border: none;
                padding: 0;


                svg {
                    fill: var(--colors-grayscale100);
                }
            }
        }

        &__close {
            order: 1;
            margin-right: 8px;
            margin-left: 12px;

            background: none;
            border: none;
            padding: 0;
            width: 24px;
            height: 24px;

            svg {
                fill: var(--colors-grayscale100);
                width: 14px;
            }

            @media (min-width: 996px) {
                margin-left: 60px;
                margin-right: 36px;

                svg {
                    width: auto;
                }
            }
        }

        &__nextEpisode {
            position: relative;
            font-size: 16px;
            line-height: 24px;
            font-weight: 600;
            color: var(--colors-grayscale100);
            background: var(--colors-grayscale25);
            padding: 10px 20px;
            border: none;
            display: none;

            @media (min-width: 996px) {
                display: block;
            }

            &::before {
                content: 'Odtwórz następny odcinek';
                position: absolute;
                padding: 10px 20px;
                background: var(--colors-primary);
                color: var(--colors-grayscale0);
                inset: 0;
                height: 100%;
                white-space: nowrap;
                overflow: hidden;

                :global {
                    animation: 10s podcastNextEpisode linear;
                }
            }
        }

        &__skipTime {
            order: 0;
            display: flex;
            gap: 1rem;
            margin-left: auto;

            @media(min-width: 996px) {
                order: unset;
                margin-right: 3rem;
            }

            button {
                padding: 0;
                border: none;
                background: transparent;
                color: var(--colors-grayscale100);

                svg {
                    width: 32px;
                    height: 32px;
                    fill: var(--colors-grayscale100);
                }
            }

            &--invert {
                img {
                    filter: invert(1);
                }
            }
        }
    }
}