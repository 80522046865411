.hp__box__popular_bars-wrapper {
	margin-right: 35px;
}

.hp__box__popular-bar {
    height: 70px;
    display: flex;
    align-items: center;
    position: relative;
    margin-top: 1px;
    &-title {
        color: #fff;
        max-width: 280px;
        text-align: left;
        margin-left: 20px;
        font-weight: 700;
        text-transform: uppercase;
        
        white-space: nowrap;
        display: block;
        overflow: hidden;
        text-overflow: ellipsis;
        margin-right: 33px;
    }
    
    background: #79a0f7;
    min-width: 35% !important;
    &:nth-child(1) {background: #3b17d5;}
    &:nth-child(2) {background: #4347f0;}
    &:nth-child(3) {background: #5463f4;}
}

.hp__box__popular__bar-comment-position {
    position: absolute;
    right: -35px;    
}

.hp__box__popular__bar-comment-wrapper {
    color: #fff;
    img {
        position: absolute;
    }
}

.hp__box__popular__bar-comment-value {
    position: absolute;
    left: 0; right: 0;
    top: 16px;
    text-align: center;
    color: #fff;
}
